import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import {useLocation} from "react-router-dom";
import Hero from 'components/Hero'
import {useState, Fragment, useEffect} from 'react'
import {SuperSEO} from "react-super-seo";
import About from 'components/About'
import HowItWork from 'components/HowItWork'
import Testimonials from 'components/Testimonials'
import Faq from 'components/Faq'
import Pricing from 'components/Pricing'
import {Dialog, Transition} from '@headlessui/react'
import logo from 'assets/images/logo2.png'
import http from "../utils/axios";
import {ReservationForm} from "../components/ReserveationForm";
import {useTranslation} from "react-i18next";

const Home = () => {
    let videoReference = React.createRef();
    const {t} = useTranslation();
    let [isOpen, setIsOpen] = useState(false)
    let [videoIsOpen, setVideoIsOpen] = useState(false)
    let [reservationOpen, setReservationOpen] = useState(false)
    let [successAlertIsOpen, setSucessAlertIsOpen] = useState(false)
    let [errorAlertIsOpen, setErrorAlertIsOpen] = useState(false)
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");
    const status = new URLSearchParams(search).get("status");
    const mode = new URLSearchParams(search).get("mode");

    function closeModal() {
        setIsOpen(false)
        localStorage.setItem('hasSeenModal', true);
    }

    function closeReservationModal() {
        setReservationOpen(false)
    }

    function openReservationModal() {
        setReservationOpen(true)
    }

    function showVideo() {
        setVideoIsOpen(true)
        videoReference.current.play();
        videoReference.current.currentTime = 0;
    }

    function closeVideo() {
        setVideoIsOpen(false)
        videoReference.current.pause();
    }

    useEffect(() => {
        if (mode=='reservation') {

            if (status === 'cancel') {
                http.get(`payments/reservations/failed/${id}`)
                    .then(async (resp) => {
                    }).catch((error) => {
                })
                setErrorAlertIsOpen(true)
            }
            if (status === 'success') {
                http.get(`payments/reservations/${id}`)
                    .then(async (resp) => {
                    }).catch((error) => {
                })
                setSucessAlertIsOpen(true)
            }
        } else if(mode=== 'coaching') {
            if (status === 'cancel') {
                http.get(`payments/failed/${id}`)
                    .then(async (resp) => {
                    }).catch((error) => {
                })
                setErrorAlertIsOpen(true)
            }
            if (status === 'success') {
                http.get(`payments/${id}`)
                    .then(async (resp) => {
                    }).catch((error) => {
                })
                setSucessAlertIsOpen(true)
            }
        }

        if (!localStorage.hasSeenModal) {
            setIsOpen(true)
        }
    }, [mode])
    return (
        <>
            <SweetAlert
                success
                title={t('sweat-alert.success-title')}
                onConfirm={() => {
                    setSucessAlertIsOpen(false)
                }}
                show={successAlertIsOpen}
                customButtons={
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setSucessAlertIsOpen(false)
                            }}
                            className={'py-4 px-20 bg-black text-white font-black text-sm rounded-full inline-block'}
                        >
                            { t('buttons.close') }
                        </button>
                    </React.Fragment>
                }
            >

                { t('sweat-alert.success-message')}
            </SweetAlert>
            <SweetAlert
                danger
                title={t('sweat-alert.error-title')}
                onConfirm={() => {
                    setErrorAlertIsOpen(false)
                }}
                show={errorAlertIsOpen}
                customButtons={
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setErrorAlertIsOpen(false)
                            }}
                            className={'py-4 px-20 bg-black text-white font-black text-sm rounded-full inline-block'}
                        >
                            { t('buttons.close') }
                        </button>
                    </React.Fragment>
                }
            >
                { t('sweat-alert.error-message')}
            </SweetAlert>
            <SuperSEO
                title="Coach Komi Afetse"
                description="Salut! Je suis Komi Afetse, un Africain qui s’est retrouvé il y a plus de 17 ans aux États-Unis, seul et sans repère. Cela n’a pas été facile, mais aujourd’hui, je vis mes rêves et j’aide des centaines de personnes à faire de même aux États-Unis ou partout dans le monde"
                lang="en"
                openGraph={{
                    ogUrl: `${process.env.REACT_APP_HOST_DOMAIN}`,
                    ogImage: {
                        ogImage: `${process.env.REACT_APP_HOST_DOMAIN}/images/photo_2022-01-23_22-50-20.jpg`,
                        ogImageAlt: "Coach Komi Afetse",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: `${process.env.REACT_APP_HOST_DOMAIN}/images/photo_2022-01-23_22-50-20.jpg`,
                        summaryCardImageAlt: "Komi Afetse",
                        summaryCardTitle: "Coach Komi Afetse",
                        summaryCardSiteUsername: "Komi Afetse",
                    },
                }}
            />
            <Transition appear show={reservationOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 h-full bg-white md:bg-black-10 md:bg-opacity-80 z-[10000] flex md:items-center justify-center overflow-y-auto p-8"
                    onClose={() => null}
                >
                    <div className="bg-white rounded-xl max-w-md lg:max-w-3xl w-full relative">
                        <button
                            className="absolute top-0 right-0 w-10 h-10 rounded-full flex items-center justify-center text-gray-80 hover:text-black-10 text-opacity-80  border-[3px] hover:border-black-10 z-50 m-3"
                            onClick={closeReservationModal}
                        >
                            <svg className="fill-current w-6" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M7.11598 8.00001L2.55798 12.558L3.44198 13.442L7.99998 8.88401L12.558 13.442L13.442 12.558L8.88398 8.00001L13.442 3.44201L12.558 2.55801L7.99998 7.11601L3.44198 2.55801L2.55798 3.44201L7.11598 8.00001Z"
                                      fill="current"/>
                            </svg>
                        </button>
                        <div className="max-w-sm lg:max-w-3xl mx-auto px-10 py-16">
                            <ReservationForm reservationFormOpener={setReservationOpen}/>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 h-full bg-white md:bg-black-10 md:bg-opacity-80 z-[10000] flex md:items-center justify-center overflow-y-auto p-8"
                    onClose={() => null}
                >
                    <div className="bg-white rounded-xl max-w-md lg:max-w-2xl w-full relative">
                        <div className="max-w-sm lg:max-w-lg mx-auto px-10 py-16">
                            <div className="text-center mb-4">
                                <a href="#" className="inline-block"><img src={logo} alt="" className="w-32"/></a>
                            </div>
                            <p className="text-lg md:text-xl text-center text-black-10 font-semibold mb-6 lg:mb-12">
                                {t('conditions.info')}
                            </p>

                            <div className="text-center" style={{'cursor': 'pointer'}}>
                                <button
                                    className="bg-black px-8 py-3 rounded-full text-white text-base font-bold inline-block uppercase"
                                    onClick={closeModal}>
                                    {t('buttons.i-understand')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <Hero reservationFormOpener={setReservationOpen}/>
            <section className="container xl:max-w-7xl mx-auto lg:px-16 px-4 py-[100px]">
                <div className="max-w-[616px] mx-auto text-center">
                    <h1 className="text-2xl sm:text-4xl md:text-42 font-black text-black-10 mb-4 md:mb-7">
                        {t('presentation.title')}
                    </h1>
                    <p className="text-base sm:text-lg md:text-xl md:mb-12 mb-10 text-gray-80">
                        {t('presentation.paragraph')}
                    </p>

                    <div class="max-w-5xl mx-auto relative">
                        <div class="cursor-pointer relative z-10 group">
                            <img src="/images/photo_2022-01-23_23-04-56.jpg" alt="" className="w-full h-full"/>
                            <div class="flex items-center justify-center absolute inset-0" onClick={showVideo}>
                                <svg class="w-20 group-hover:w-24 transition-all duration-200 ease-in-out"
                                     viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M512 64C264.6 64 64 264.6 64 512C64 759.4 264.6 960 512 960C759.4 960 960 759.4 960 512C960 264.6 759.4 64 512 64ZM656.1 518.9L437.7 677.8C436.503 678.66 435.091 679.174 433.621 679.285C432.151 679.395 430.679 679.098 429.366 678.426C428.054 677.755 426.952 676.734 426.181 675.477C425.411 674.22 425.002 672.774 425 671.3V353.7C424.995 352.223 425.399 350.773 426.168 349.511C426.937 348.25 428.04 347.225 429.355 346.552C430.67 345.879 432.146 345.583 433.619 345.698C435.092 345.812 436.504 346.332 437.7 347.2L656.1 506C657.132 506.73 657.973 507.696 658.554 508.819C659.135 509.941 659.438 511.186 659.438 512.45C659.438 513.714 659.135 514.959 658.554 516.081C657.973 517.204 657.132 518.17 656.1 518.9V518.9Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div
                            className={`inset-0 bg-black/60 flex items-center transition ease-out duration-300 ${videoIsOpen ? 'opacity-100 fixed z-50' : 'opacity-0 z-0 hidden'}`}>
                            <div className="relative max-w-2xl mx-auto w-full">
                                <button className="absolute md:-top-10 md:-right-10 -top-10 right-0" onClick={closeVideo}>
                                    <svg
                                        className="fill-slate-300 hover:fill-white transition ease-out duration-300"
                                        width="32"
                                        height="32" viewBox="0 0 1024 1024" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M195.2 195.2C207.202 183.202 223.477 176.462 240.448 176.462C257.419 176.462 273.694 183.202 285.696 195.2L512 421.504L738.304 195.2C750.375 183.542 766.541 177.091 783.322 177.237C800.102 177.383 816.154 184.113 828.02 195.98C839.887 207.846 846.617 223.898 846.763 240.678C846.909 257.459 840.458 273.625 828.8 285.696L602.496 512L828.8 738.304C840.458 750.375 846.909 766.541 846.763 783.322C846.617 800.102 839.887 816.154 828.02 828.02C816.154 839.887 800.102 846.617 783.322 846.763C766.541 846.909 750.375 840.458 738.304 828.8L512 602.496L285.696 828.8C273.625 840.458 257.459 846.909 240.678 846.763C223.898 846.617 207.846 839.887 195.98 828.02C184.113 816.154 177.383 800.102 177.237 783.322C177.091 766.541 183.542 750.375 195.2 738.304L421.504 512L195.2 285.696C183.202 273.694 176.462 257.419 176.462 240.448C176.462 223.477 183.202 207.202 195.2 195.2V195.2Z"
                                            fill="current"
                                        />
                                    </svg>
                                </button>
                                <video className="aspect-video w-full" controls ref={videoReference}>
                                    <source
                                        src="/images/Zenfolio - Sergio Photography - Chasing my dream.mp4"
                                        type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-20">
                <div className="container xl:max-w-7xl mx-auto lg:px-16 px-4 pt-20 pb-[100px]">
                    <div className="grid lg:grid-cols-12 gap-6">
                        <div className="lg:col-span-7">
                            <div className="max-w-[590px]">
                                <h1
                                    className="text-4xl md:text-5xl lg:text-6xl xl:text-64 xl:leading-[77px] font-black uppercase mb-9">
                                    {t('dream-chasing.title')} <span
                                    className="italic"> {t('dream-chasing.italic')} </span></h1>
                                <p className="text-base sm:text-lg md:text-xl md:leading-6 mb-10 sm:mb-11 md:mb-[50px]">
                                    {t('dream-chasing.paragraph-1')}
                                    <br/>
                                    <br/>
                                    {t('dream-chasing.paragraph-2')}
                                </p>
                                <a href="https://amzn.to/3DCTHcH" target="_blank"
                                   className="inline-flex items-center justify-center px-8 py-4 bg-black rounded-full text-sm text-white font-black space-x-3">
                                    <span className='uppercase'>
                                        {t('buttons.i-want-book')}
                                    </span>
                                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="lg:col-span-5">
                            <img src='/images/photo_2022-01-23_23-01-58.jpg' alt="book-cover"
                                 className="w-[400px] lg:ml-auto"/>
                        </div>
                    </div>
                </div>
            </section>

            <About/>

            <Pricing/>

            <HowItWork reservationFormOpener={setReservationOpen}/>

            <Testimonials reservationFormOpener={setReservationOpen}/>

            <Faq/>
        </>
    )
}

export default Home
