import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT;
// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

class Http extends axios {
    constructor() {
        super()
    }
    static setHeader = function (key, value) {
        this.defaults.headers.post[key] = value;
    }
}

export default Http;
