import React from 'react'
import {Disclosure, Transition} from '@headlessui/react'
import {useTranslation} from "react-i18next";

/*
[3:06 PM, 1/18/2022] Komi Maria Afetse: 1-
[3:07 PM, 1/18/2022] Komi Maria Afetse: 2-
[3:08 PM, 1/18/2022] Komi Maria Afetse: 3-
[3:09 PM, 1/18/2022] Komi Maria Afetse: 4-
[3:10 PM, 1/18/2022] Komi Maria Afetse: on laisse tombe la question de combien de temps prend le process
 */
const faq = [
    {
        question: "faq.elements.1.question",
        answer: "faq.elements.1.answer"
    },
    {
        question: "faq.elements.2.question",
        answer: "faq.elements.2.answer"
    },
    /*
    {
        question: "A qui sont destinees les formation du programme?",
        answer: "Les formations sont destinées a tous ceux qui vivent ou planifient de vivre aux Etats Unis / Canada et qui veulent réussir leurs vies."
    },*/
    {
        question: "faq.elements.3.question",
        answer: "faq.elements.3.answer"
    },
    /*
    {
        question: "Combien de temps prend le process de coaching transformationnelle?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sem mauris pulvinar ullamcorper egestas tellus pellentesque arcu egestas."
    },
    */
    {
        question: "faq.elements.4.question",
        answer: "faq.elements.4.answer"
    },
]

const Faq = () => {
    const {t} = useTranslation();
    return (
        <section class="container md:max-w-3xl mx-auto md:px-2 px-4 pt-16 pb-[87px]" id={'faq'}>
            <h1
                class="text-2xl sm:text-3xl md:text-4xl xl:text-42 xl:leading-[50px] text-black-10 font-black text-center uppercase mb-7">
                {t('faq.title')}
            </h1>

            {
                faq.map((element, index) => (
                    <Disclosure
                        defaultOpen={index === 0}
                        key={index}
                    >
                        {({open}) => (
                            <div
                                className={`px-5 sm:px-10 py-5 transition duration-300 ease-in-out mb-[22px] ${open ? 'bg-gray-20' : 'border border-black'}`}>
                                <Disclosure.Button
                                    className="flex items-center justify-between space-x-4 cursor-pointer mb-1"
                                    as="div">
                                    <h5 className="text-base sm:text-lg font-bold text-black-10">
                                        {t(element.question)}
                                    </h5>
                                    <svg
                                        className={`transition duration-100 ease-out min-w-[22px] ${!open && 'transform rotate-45'}`}
                                        width="22" height="22" viewBox="0 0 22 22"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                            stroke="#1B1B1B" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path d="M14 8L8 14" stroke="#1B1B1B" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                        <path d="M8 8L14 14" stroke="#1B1B1B" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                </Disclosure.Button>
                                <Transition
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 transform scale-90"
                                    enterTo="opacity-100 transform scale-100"
                                    leave="transition ease-in duration-200"
                                    leaveFrom="opacity-100 transform scale-100"
                                    leaveTo="opacity-0 transform scale-90"
                                />
                                <Disclosure.Panel className="max-w-xl origin-top transform">
                                    <p className="text-base md:text-lg text-gray-70">{t(element.answer)}</p>
                                </Disclosure.Panel>
                            </ div>
                        )}
                    </Disclosure>
                ))}


        </section>
    )
}

export default Faq
