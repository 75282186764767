const messages = {
    "fr": {
        "alpha": "Le champ :field ne peut contenir que des lettres",
        "alpha_num": "Le champ :field ne peut contenir que des caractères alpha-numériques",
        "alpha_dash": "Le champ :field ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
        "alpha_spaces": "Le champ :field ne peut contenir que des lettres ou des espaces",
        "range": "Le champ :field doit être compris entre :value et :value1",
        "confirmed": "Le champ :field ne correspond pas",
        "digits": "Le champ :field doit être un nombre entier de :value chiffres",
        "dimensions": "Le champ :field doit avoir une taille de {width} pixels par {height} pixels",
        "email": "Le champ :field doit être une adresse e-mail valide",
        "phone": "Le champ :field doit être un numéro de téléphone correcte",
        "excluded": "Le champ :field doit être une valeur valide",
        "ext": "Le champ :field doit être un fichier valide",
        "image": "Le champ :field doit être une image",
        "integer": "Le champ :field doit être un entier",
        "length": "Le champ :field doit contenir :value caractères",
        "max": "Le champ :field doit avoir une valeur de :value ou moins",
        "maxLength": "Le champ :field ne peut pas contenir plus de :value caractères",
        "mimes": "Le champ :field doit avoir un type MIME valide",
        "min": "Le champ :field doit avoir une valeur de :value ou plus",
        "minLength": "Le champ :field doit contenir au minimum :value caractères",
        "number": "Le champ :field ne peut contenir que des chiffres",
        "oneOf": "Le champ :field doit être une valeur valide",
        "regex": "Le champ :field est invalide",
        "required": "Le champ :field est obligatoire",
        "url": "Le champ :field doit être une url valide",
        "size": "Le champ :field doit avoir un poids inférieur à {size}KB",
        "creditCard": "Le champ :field doit être une carte de crédit valide",
        "date": "Le champ :field doit être une date valide",
        "minDate": "La date du champ :field doit être postérieur à :value",
        "maxDate": "La date du champ :field doit être antérieur à :value",
        "equal": "La valeur du champ :field doit être egale à :value",
        "lt": "La valeur du champ :field doit être inférieur à :value",
        "lte": "La valeur du champ :field doit être inférieur ou egale à :value",
        "gt": "La valeur du champ :field doit être supérieur à :value",
        "gte": "La valeur du champ :field doit être supérieur ou egale à :value",
        "equalTo": "Le champ :field ne correspond pas au champs :value."
    },
    "en": {
        "alpha": "The field :field can only contain letters",
        "alpha_num": "The field :field can only contain alpha-numeric characters",
        "alpha_dash": "The field :field can only contain alpha-numeric, dash or underscore characters",
        "alpha_spaces": "The field :field can only contain letters or spaces",
        "range": "The field :field must be between :value and :value1",
        "confirmed": "The field :field does not match",
        "digits": "The field :field must be an integer of :value digits",
        "dimensions": "The field :field must have a size of {width} pixels by {height} pixels",
        "email": "The field :field must be a valid email address",
        "excluded": "The field :field must be a valid value",
        "ext": "The field :field must be a valid file",
        "image": "The field :field must be an image",
        "integer": "The field :field must be an integer",
        "length": "The field :field must contain :value characters",
        "max": "The field :field must have a value of {max} or less",
        "maxLength": "The field :field cannot contain more than :value characters",
        "mimes": "The field :field must have a valid MIME type",
        "min": "The field :field must have a value of {min} or greater",
        "minLength": "The field :field must contain at least :value characters",
        "number": "The field :field can only contain numbers",
        "oneOf": "The field :field must be a valid value",
        "regex": "The field :field is invalid",
        "required": "The field :field is required",
        "url": "The field :field must be a valid url",
        "size": "The field :field must have a weight less than {size}KB",
        "creditCard": "The field :field must be a valid credit card",
        "date": "The field :field must be a valid date",
        "minDate": "The date of field :field must be later than :value",
        "maxDate": "The date of field :field must be before :value",
        "equal": "The value of field :field must be equal to :value",
        "lt": "The value of field :field must be less than :value",
        "lte": "The value of field :field must be less than or equal to :value",
        "gt": "The value of field :field must be greater than :value",
        "gte": "The value of field :field must be greater than or equal to :value",
        "equalTo": "The field :field does not match the :value field."
    }
}
export default messages;
