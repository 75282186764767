const typeVisas = [
    'Citizen',
    'Green Card',
    'H1B',
    'O1',
    'Étudiant',
    'Autres'
]

export default typeVisas
