import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
const index = ({ component: Component, ...props }) => {
    return (
        <>
            <Navbar />
            <Component {...props} />
            <Footer />
        </>
    )
}

export default index
