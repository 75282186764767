import React from 'react'
import Faq from 'components/Faq'
import {SuperSEO} from "react-super-seo";
import {useTranslation} from "react-i18next";

const History = () => {
    const {t} = useTranslation();
    return (
        <>
            <SuperSEO
                title="Coach Komi Afetse :: Mon histoire"
                description="Salut! Je suis Komi Afetse, un Africain qui s’est retrouvé il y a plus de 17 ans aux États-Unis, seul et sans repère. Cela n’a pas été facile, mais aujourd’hui, je vis mes rêves et j’aide des centaines de personnes à faire de même aux États-Unis ou partout dans le monde"
                lang="en"
                openGraph={{
                    ogUrl: `${process.env.REACT_APP_HOST_DOMAIN}`,
                    ogImage: {
                        ogImage: `${process.env.REACT_APP_HOST_DOMAIN}/images/photo_2022-01-23_22-50-20.jpg`,
                        ogImageAlt: "Coach Komi Afetse",
                        ogImageWidth: 1200,
                        ogImageHeight: 630,
                        ogImageType: "image/jpeg",
                    },
                }}
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: `${process.env.REACT_APP_HOST_DOMAIN}/images/photo_2022-01-23_22-50-20.jpg`,
                        summaryCardImageAlt: "Komi Afetse",
                        summaryCardTitle: "Coach Komi Afetse :: Mon histoire",
                        summaryCardSiteUsername: "Komi Afetse",
                    },
                }}
            />

            <section className="container xl:max-w-7xl mx-auto lg:px-16 px-4 mb-16 md:mb-24 lg:mb-32 xl:mb-[147px]">
                <div className="grid md:grid-cols-2 gap-10 lg:gap-14 xl:gap-[68px] items-center">
                    <div>
                        <img src='/images/photo_2022-01-22_23-23-48-ALT.jpg' alt="hero section"
                             className="w-full h-[300px] sm:h-[400px] md:h-[480px] lg:h-[594px] object-cover object-top"/>
                    </div>
                    <div className="text-center md:text-left">

                        <h4 className="text-lg sm:text-xl lg:text-2xl font-medium leading-[29px] text-yellow-10 mb-2">
                            { t('my-history.banner.subtitle') }
                        </h4>
                        <h1
                            className="text-3xl sm:text-4xl lg:text-5xl xl:text-52 xl:leading-[62px] text-black-10 font-black uppercase">
                            { t('my-history.banner.title') }
                        </h1>
                    </div>
                </div>
            </section>

            <section className="container xl:max-w-7xl mx-auto lg:px-16 px-4 mb-16 md:mb-24 lg:mb-32 xl:mb-40">
                <div className="grid lg:grid-cols-2 xl:grid-cols-12 gap-10 lg:gap-0 items-center">
                    <div
                        className="xl:col-span-7 bg-white shadow-10 p-6 sm:p-8 lg:py-10 lg:pl-12 lg:pr-8 lg:-mr-12 relative z-10 order-2 lg:order-1">
                        <h1 className="text-2xl md:text-3xl lg:text-4xl font-black uppercase lg:leading-[43px] mb-6">
                            { t('my-history.who-am-i.title') }
                        </h1>
                        <p className="text-base sm:text-lg md:text-xl xl:leading-6 text-gray-80">
                            { t('my-history.who-am-i.paragraph') }
                        </p>
                    </div>
                    <div className="xl:col-span-5 order-1 lg:order-2">
                        <img src='/images/garde.jpg' alt="img"
                             className="w-full h-[380px] sm:h-[500px] lg:h-[900px] xl:h-[630px] object-cover object-top"/>
                    </div>
                </div>
            </section>

            <section className="container md:max-w-4xl mx-auto md:px-11 px-4 mb-12 lg:mb-16 xl:mb-20">
                <h1 className="text-3xl md:text-4xl font-black text-black-10 text-center mb-[52px]">
                    { t('my-history.determination.title') }
                </h1>

                <img src={'/images/story-determination.jpg'} alt="img" className="w-full h-[480px] object-cover mb-10"/>

                <p className="text-base sm:text-lg md:text-xl text-gray-80 md:leading-6 max-w-3xl mx-auto px-2">
                    { t('my-history.determination.paragraph-1') }
                    <br/>
                    <br/>
                    { t('my-history.determination.paragraph-2') }
                </p>
            </section>

            <section className="bg-black-10 w-full py-[74px] mb-14 lg:mb-20 xl:mb-24">
                <div className="container md:max-w-[818px] mx-auto px-4 md:px-2">
                    <h1
                        className="text-2xl sm:text-3xl md:text-4xl xl:text-42 xl:leading-[50px] text-white font-black text-center uppercase mb-10">
                        { t('my-history.i-believe.title') }
                    </h1>

                    <p className="text-base sm:text-lg md:text-xl md:leading-6 text-white">
                        { t('my-history.i-believe.paragraph-1') }
                        <br/>
                        <br/>
                        { t('my-history.i-believe.paragraph-2') }
                        <span className="font-black">
                            { t('my-history.i-believe.paragraph-3') }
                        </span>
                        { t('my-history.i-believe.paragraph-4') }
                    </p>
                </div>
            </section>

            <section className="container md:max-w-3xl mx-auto md:px-2 px-4 mb-16 md:mb-20 lg:mb-28 xl:mb-[122px]">
                <img src={'/images/img-4.png'} alt="img" className="w-full h-[338px] object-cover mb-[53px]"/>
                <h1
                    className="text-2xl sm:text-3xl md:text-4xl xl:text-42 xl:leading-[50px] text-black-10 font-black text-center uppercase mb-8"
                >
                    { t('my-history.conference.title') }
                </h1>
                <p className="text-base sm:text-lg md:text-xl md:leading-6 text-gray-80 mb-11">
                    { t('my-history.conference.paragraph-1') }
                </p>

                <div className="grid md:grid-cols-12 gap-10">
                    <div className="md:col-span-7 border border-black">
                        <div className="w-full h-1.5 bg-black-10"></div>
                        <div className="pt-6 pb-11 pl-[22px] pr-[22px]">
                            <h5 className="text-base sm:text-lg md:text-xl font-black text-black-10 mb-8 uppercase">
                                { t('my-history.conference.subtitle') }
                            </h5>
                            <div className="flex items-center space-x-3 mb-[10px]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.5 17.794a8.958 8.958 0 0 1-4.5 1.207M14 10a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm0 0v1.5a2.5 2.5 0 0 0 5 0V10a9 9 0 1 0-9 9l4-9Z"
                                        stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <p className="text-lg text-black-10">komi@komiafetse.com</p>
                            </div>
                            <div className="flex items-center space-x-3 mb-8 md:mb-[50px]">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 3a2 2 0 0 1 2-2h3.28a1 1 0 0 1 .948.684l1.498 4.493a1 1 0 0 1-.502 1.21l-2.257 1.13a11.042 11.042 0 0 0 5.516 5.516l1.13-2.257a1 1 0 0 1 1.21-.502l4.493 1.498a1 1 0 0 1 .684.949V17a2 2 0 0 1-2 2h-1C7.716 19 1 12.284 1 4V3Z"
                                        stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <p className="text-lg text-black-10">+12024998934</p>
                            </div>

                            <p className="text-base md:text-lg text-black-10 md:leading-[22px]">
                                { t('my-history.conference.paragraph-2') }
                                <span className="font-black">
                                { t('my-history.conference.paragraph-3') }
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="md:col-start-9 md:col-span-4 flex items-center justify-center">
                        <div className="w-72 sm:w-[300px] md:w-full ">
                            <img src={'/images/book-cover.png'} alt="" className="w-full mb-5"/>
                            <a href='https://amzn.to/3DCTHcH' target={'_blank'}
                               className="flex items-center justify-center px-8 py-4 bg-black rounded-full text-sm text-white font-black space-x-3">
                                <span>
                                    {t('buttons.i-want-book')}
                                </span>
                                <svg width="8" height="13" viewBox="0 0 8 13" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L6.5 6.5L1 12" stroke="white" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <Faq/>
        </>
    )
}

export default History
