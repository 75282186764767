import React from 'react'
import {useTranslation} from "react-i18next";

const testimonials = [
    {
        username: 'Razak',
        job: 'testimonial.quotes.aurelie.job',
        profile: '/images/raz.jpg',
        quote: 'testimonial.quotes.aurelie.content'
    },
    {
        username: 'Nadia',
        job: 'testimonial.quotes.black.job',
        quote: 'testimonial.quotes.black.content',
        profile: '/images/nadia.jpeg',
    },
    {
        username: 'Anani',
        job: 'testimonial.quotes.anani.job',
        quote: 'testimonial.quotes.anani.content',
        profile: '/images/man-2.jpg',
    },
]
const Testimonials = ({reservationFormOpener}) => {
    const { t } = useTranslation();
    return (
        <section className="bg-gray-20">
            <div class="container xl:max-w-7xl mx-auto lg:px-16 px-4 pt-16 pb-[86px]">
                <h1
                    class="text-2xl sm:text-4xl md:text-42 font-black text-black-10 uppercase mb-10 md:mb-[70px] text-center">
                    { t('testimonial.title')} </h1>

                <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-10 mb-16">
                    {
                        testimonials.map( item => {
                            return (
                                <div className="bg-white shadow-10 pt-9 px-[14px] pb-6 flex flex-col justify-between">
                                    <p className="text-sm sm:text-base lg:text-lg italic text-gray-70 mb-5">
                                        { t(item.quote) }
                                    </p>
                                    <div className="flex items-center space-x-[10px]">
                                        <div><img src={item.profile} alt=""
                                                  className="w-[56px] h-[56px] rounded-full object-cover "/></div>
                                        <div>
                                            <h6 className="text-base leading-5 font-black text-gray-70 mb-1"> {item.username} </h6>
                                            <p className="text-base leading-5 text-gray-70">{ t(item.job) }</p>
                                        </div>
                                    </div>
                                </div>)
                        })
                    }
                </div>

                <div class="text-center">
                    <button
                        onClick={() => {
                            reservationFormOpener(true)
                        }}
                        class="text-sm font-black text-white uppercase leading-4 py-4 px-6 bg-black rounded-full inline-block">
                        { t('buttons.free-reservation') }
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
