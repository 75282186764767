import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from 'react'
import routes from 'router'
import Layout from 'layouts/index'
import {useTranslation} from "react-i18next";

const Mainrouter = () => {
    const {t, ready } = useTranslation();
    return (
        <>
            {
                !ready ? (<div className={'flex'}>
                    <div className="mt-[100%] md:mt-[15%] mx-auto w-32 md:w-48">
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                             viewBox="0 0 1755.000000 1139.000000" preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,1139.000000) scale(0.100000,-0.100000)" fill="#000000"
                               stroke="none">
                                <path d="M6664 11372 c-84 -30 -143 -87 -175 -173 -16 -41 -19 -77 -19 -227 0
-97 3 -241 7 -319 l6 -143 428 0 c399 0 428 1 433 18 25 86 39 581 18 653 -21
71 -78 139 -147 174 l-58 30 -221 2 c-191 3 -228 0 -272 -15z" className="svg-elem-1"></path>
                                <path d="M10813 11359 c-334 -25 -657 -159 -908 -376 -69 -59 -2154 -2347
-2155 -2364 0 -13 2105 -3529 2158 -3604 77 -108 286 -315 402 -398 124 -87
337 -194 481 -241 204 -66 276 -75 679 -91 201 -8 408 -14 460 -14 l95 0 -77
24 c-272 83 -501 227 -641 402 -74 93 -2510 3912 -2504 3926 7 17 1982 2072
2052 2135 172 154 446 332 740 480 110 55 209 108 220 116 18 14 -25 15 -440
14 -253 0 -506 -4 -562 -9z" className="svg-elem-2"></path>
                                <path d="M6485 10268 c-27 -84 -45 -1473 -26 -2031 29 -815 96 -1821 141
-2102 34 -214 95 -421 181 -612 33 -74 130 -243 139 -243 11 0 98 152 139 242
109 236 176 497 206 801 91 927 146 2330 125 3191 -9 393 -25 725 -35 754 -3
9 -100 12 -435 12 -336 0 -432 -3 -435 -12z" className="svg-elem-3"></path>
                                <path d="M11040 3016 c-77 -20 -138 -58 -194 -116 -99 -104 -152 -248 -163
-442 l-6 -107 -111 -3 -111 -3 0 -85 0 -85 113 -3 112 -3 0 -724 0 -725 110 0
110 0 0 725 0 725 223 2 222 3 0 85 0 85 -224 3 -224 2 6 113 c8 175 59 288
155 344 43 26 55 28 152 28 98 0 110 -2 167 -31 l62 -30 32 72 c17 40 28 77
24 83 -12 20 -106 62 -183 82 -85 22 -203 24 -272 5z" className="svg-elem-4"></path>
                                <path d="M6960 2810 l0 -190 175 0 175 0 0 190 0 190 -175 0 -175 0 0 -190z"
                                      className="svg-elem-5"></path>
                                <path d="M1124 2371 l-519 -569 -3 564 -2 564 -175 0 -175 0 0 -1105 0 -1105
175 0 175 0 0 333 0 332 170 175 c163 168 170 174 185 155 8 -11 176 -239 373
-507 l357 -488 197 0 198 0 -222 298 c-122 163 -329 440 -460 615 -149 198
-236 322 -231 330 5 9 598 677 846 955 l20 22 -195 0 -195 0 -519 -569z" className="svg-elem-6"></path>
                                <path d="M8797 1848 c-251 -601 -459 -1101 -463 -1110 -6 -17 4 -18 112 -18
l119 0 140 345 140 345 498 -3 499 -2 141 -342 142 -343 117 0 118 0 -9 23
c-10 26 -863 2079 -893 2150 l-20 47 -92 0 -92 0 -457 -1092z m767 288 c119
-292 216 -533 216 -538 0 -4 -198 -8 -441 -8 -416 0 -441 1 -434 18 34 90 439
1062 441 1060 1 -2 100 -241 218 -532z" className="svg-elem-7"></path>
                                <path d="M13447 2894 c-4 -4 -7 -128 -7 -276 l0 -268 -110 0 -110 0 0 -90 0
-90 110 0 110 0 0 -602 c0 -402 4 -616 11 -643 26 -95 108 -178 203 -208 70
-21 240 -15 326 12 102 31 170 61 170 75 0 6 -12 46 -26 88 l-25 77 -71 -31
c-48 -20 -95 -32 -144 -35 -66 -5 -79 -3 -129 21 -100 50 -95 14 -95 676 l0
570 185 0 185 0 0 90 0 90 -185 0 -185 0 -2 273 -3 272 -101 3 c-55 1 -103 0
-107 -4z" className="svg-elem-8"></path>
                                <path d="M2735 2364 c-394 -87 -655 -418 -655 -829 0 -334 172 -623 455 -760
143 -70 193 -80 390 -80 166 0 172 1 265 33 215 75 379 215 475 407 112 225
125 491 34 726 -90 233 -294 418 -541 490 -99 29 -322 36 -423 13z m376 -326
c103 -48 177 -124 229 -232 47 -99 60 -160 60 -278 0 -109 -19 -190 -68 -283
-97 -184 -281 -282 -479 -254 -183 25 -346 179 -398 375 -19 72 -22 232 -5
305 22 96 78 198 150 270 106 108 186 140 335 136 92 -2 106 -5 176 -39z" className="svg-elem-9"></path>
                                <path d="M4822 2361 c-154 -38 -290 -130 -389 -265 l-43 -58 0 156 0 156 -160
0 -160 0 0 -815 0 -815 175 0 174 0 3 498 3 499 40 79 c101 199 307 312 485
264 68 -18 142 -98 172 -187 22 -67 22 -76 26 -610 l3 -543 175 0 174 0 0 494
0 494 42 83 c52 105 145 202 233 244 48 23 78 29 145 33 78 3 90 1 140 -25 98
-50 140 -131 160 -304 6 -46 10 -294 10 -551 l0 -468 170 0 170 0 0 569 c0
605 -4 671 -51 795 -46 125 -140 225 -251 268 -73 29 -237 35 -338 14 -156
-33 -321 -144 -415 -280 -40 -58 -55 -69 -55 -38 0 36 -61 145 -112 198 -90
95 -196 135 -358 133 -55 0 -126 -8 -168 -18z" className="svg-elem-10"></path>
                                <path d="M12151 2370 c-429 -59 -730 -430 -708 -875 6 -133 32 -237 87 -348
107 -220 303 -379 540 -438 71 -18 104 -21 220 -17 118 4 147 9 230 37 127 43
221 100 305 185 60 61 135 170 123 181 -5 5 -157 45 -172 45 -8 0 -26 -20 -40
-44 -57 -97 -201 -195 -336 -227 -196 -47 -410 19 -554 171 -95 101 -175 275
-176 382 l0 28 693 2 692 3 3 78 c12 320 -190 647 -476 774 -125 55 -300 81
-431 63z m185 -171 c199 -27 365 -153 455 -344 31 -66 59 -164 59 -207 l0 -28
-595 0 -596 0 6 38 c52 305 235 502 505 541 79 12 81 12 166 0z" className="svg-elem-11"></path>
                                <path d="M14844 2369 c-155 -18 -305 -87 -386 -177 -103 -115 -142 -302 -93
-443 47 -132 150 -187 548 -289 277 -72 382 -117 432 -188 27 -40 30 -51 30
-120 0 -87 -20 -135 -77 -189 -77 -73 -180 -105 -343 -106 -187 -1 -343 48
-508 162 -40 28 -76 51 -80 51 -4 0 -27 -30 -52 -67 -53 -79 -54 -77 63 -154
77 -51 188 -98 312 -132 104 -28 401 -31 500 -4 268 72 416 259 397 501 -18
215 -155 305 -647 426 -163 40 -213 56 -283 93 -70 37 -97 77 -104 155 -10
114 42 214 140 268 73 39 144 54 261 54 163 0 298 -42 419 -131 38 -27 70 -49
71 -47 1 2 24 29 51 62 l50 59 -50 44 c-58 51 -203 125 -290 147 -63 16 -222
37 -265 35 -14 -1 -57 -5 -96 -10z" className="svg-elem-12"></path>
                                <path d="M16541 2370 c-429 -59 -730 -430 -708 -875 6 -133 32 -237 87 -348
107 -220 303 -379 540 -438 71 -18 104 -21 220 -17 118 4 147 9 230 37 127 43
221 100 305 185 60 61 135 170 123 181 -5 5 -157 45 -172 45 -8 0 -26 -20 -40
-44 -57 -97 -201 -195 -336 -227 -196 -47 -410 19 -554 171 -95 101 -175 275
-176 382 l0 28 693 2 692 3 3 78 c12 320 -190 647 -476 774 -125 55 -300 81
-431 63z m185 -171 c199 -27 365 -153 455 -344 31 -66 59 -164 59 -207 l0 -28
-595 0 -596 0 6 38 c52 305 235 502 505 541 79 12 81 12 166 0z" className="svg-elem-13"></path>
                                <path d="M6960 1535 l0 -815 175 0 175 0 0 815 0 815 -175 0 -175 0 0 -815z"
                                      className="svg-elem-14"></path>
                            </g>
                        </svg>

                    </div>
                </div>) : (
                    <BrowserRouter>
                        <Routes>
                            {
                                routes.map((route, index) => (
                                    <Route path={route.path} element={<Layout
                                        component={route.component}
                                    />}/>
                                ))
                            }
                        </Routes>
                    </BrowserRouter>
                )
            }
        </>
    )
}

export default Mainrouter
