import Home from 'pages/Home'
import History from 'pages/History'

const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/my-history",
        component: History
    }
]

export default routes;
