import React, {useState} from "react";
import {useForm} from "react-hook-form";

import FormValidations from "../utils/custom-validation/form-validations";
import countries from "../utils/country-list";
import typeVisas from "../utils/visa-type";
import http from '../utils/axios'
import {useTranslation} from "react-i18next";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";

const yearCounts = []

for (let i = 0; i < 100; i++) {
    yearCounts.push(i);
}

export const CheckoutForm = ({amount, name, description, pack}) => {
    const [processing, setProcessing] = useState(false);
    const {t, i18n } = useTranslation();
    const {register, formState: {errors}, reset, handleSubmit} = useForm();
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [phoneValidation, setPhoneValidation] = useState({
        isValid: true,
        key: null,
    });

    const formValidation = new FormValidations(i18n.language);

    const onSubmit = async (data) => {
        setProcessing(true);
        data = {
            ...data,
            nbr_annee: parseInt(data.nbr_annee),
            amount,
            name,
            phoneNumber,
            pack,
            description
        }
        http.post(`payments`, data)
            .then(async ({data}) => {
                reset()
                setProcessing(false);
                if (data?.data) {
                    window.location.href = data?.data?.redirectUrl;
                }
            }).catch((error) => {
            setProcessing(false);
        })
    };

    const validatePhoneNumber = () => {
        if (!phoneNumber) {
            setPhoneValidation({
                isValid: false,
                key: 'required',
            })
            return 0;
        }
        if (!isValidPhoneNumber(phoneNumber+'')) {
            setPhoneValidation({
                isValid: false,
                key: 'regex',
            })
            return 0;
        }
    }

    return (
        <form className="Form" onSubmit={handleSubmit(onSubmit, validatePhoneNumber)}>
            <fieldset className="FormGroup">
                <div
                    className="grid md:grid-cols-2 gap-12 lg:gap-24 pb-12 border-b border-white border-opacity-20">
                    <div>
                        <div className="mb-6">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.username')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <input
                                type="text"
                                className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:bg-opacity-30"
                                name="username"
                                placeholder={t('checkout-form.username')}
                                {...register('username', {required: true, minLength: 6, maxLength: 60})}
                            />
                            {
                                (formValidation.has(errors.username?.type)) ? (
                                    <p className="text-red-500 mt-3">
                                        {formValidation.first(errors.username?.type, t('checkout-form.username'), 6, 60)}
                                    </p>
                                ) : null
                            }
                        </div>

                        <div className="mb-6">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.email')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <input
                                type="text"
                                className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:bg-opacity-30"
                                name="email"
                                placeholder={t('checkout-form.email')}
                                {...register('email', {
                                    required: true,
                                    pattern: {value: /\S+@\S+\.\S+/, message: ""}
                                })}
                            />
                            {
                                (formValidation.has(errors.email?.type)) ? (
                                    <p className="text-red-500 mt-3">
                                        {formValidation.first(errors.email?.type, t('checkout-form.email'), null, null)}
                                    </p>
                                ) : null
                            }
                        </div>

                        <div className="mb-6 relative">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.country')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <div className="relative">
                                <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                     width="24"
                                     height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                        fill="#F8F8F8"/>
                                </svg>
                                <select
                                    name="country"
                                    {...register('country', {
                                        required: true
                                    })}
                                    className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:text-black-10 focus:bg-opacity-30 appearance-none"
                                >
                                    {
                                        countries.map(value => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))
                                    }
                                </select>
                                {
                                    (formValidation.has(errors.country?.type)) ? (
                                        <p className="text-red-500 mt-3">
                                            {formValidation.first(errors.country?.type, t('checkout-form.country'))}
                                        </p>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="mb-6">
                            <div className="mb-6">
                                <label htmlFor="" className="text-base text-white mb-3 block">
                                    {t('checkout-form.phone-number')}
                                    <span className="text-red-500 ml-2">*</span>
                                </label>
                                <div className="flex w-full">
                                    <PhoneInput
                                        placeholder={t('reservation-form.phone-number')}
                                        value={phoneNumber}
                                        className={'text-white w-full'}
                                        onChange={(e) => {
                                            setPhoneNumber(e)
                                            setPhoneValidation({
                                                isValid: true,
                                                key: null,
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    (!phoneValidation.isValid) ? (
                                        <p className="text-red-500 mt-3">
                                            {t(`validations.${phoneValidation.key}`).replace(':field', t('reservation-form.phone-number'))}
                                        </p>
                                    ) : null
                                }
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="mb-6">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.visa-type')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <div className="relative">
                                <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                     width="24"
                                     height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                        fill="#F8F8F8"/>
                                </svg>
                                <select
                                    name="typeVisa"
                                    {...register('typeVisa', {
                                        required: true
                                    })}
                                    className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:text-black-10 focus:bg-opacity-30 appearance-none"
                                >
                                    {
                                        typeVisas.map(value => (
                                            <option key={value} value={value}>
                                                {(t(`type-visa.${value}`))}
                                            </option>
                                        ))
                                    }
                                </select>
                                {
                                    (formValidation.has(errors.typeVisa?.type)) ? (
                                        <p className="text-red-500 mt-3">
                                            {formValidation.first(errors.typeVisa?.type, t('checkout-form.visa-type'))}
                                        </p>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="mb-6">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.year-count')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <div className="relative">
                                <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                     width="24"
                                     height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                        fill="#F8F8F8"/>
                                </svg>
                                <select
                                    name="nbr_annee"
                                    {...register('nbr_annee', {
                                        required: true
                                    })}
                                    className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:text-black-10 focus:bg-opacity-30 appearance-none"
                                >
                                    {
                                        yearCounts.map(value => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))
                                    }
                                </select>
                                {
                                    (formValidation.has(errors.nbr_annee?.type)) ? (
                                        <p className="text-red-500 mt-3">
                                            {formValidation.first(errors.nbr_annee?.type, t('checkout-form.year-count'))}
                                        </p>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="mb-6">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.english-level')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <div className="mb-2 relative">
                                <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                     width="24"
                                     height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                        fill="#F8F8F8"/>
                                </svg>
                                <select
                                    name="english_level"
                                    {...register('english_level', {
                                        required: true
                                    })}
                                    className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:text-black-10 focus:bg-opacity-30 appearance-none"
                                >
                                    {
                                        ['Aucun', 'Débutant', 'Intermédiaire', 'Avancé'].map(value => (
                                            <option key={value} value={value}>
                                                {(t(`level.${value}`))}
                                            </option>
                                        ))
                                    }
                                </select>
                                {
                                    (formValidation.has(errors.english_level?.type)) ? (
                                        <p className="text-red-500 mt-3">
                                            {formValidation.first(errors.english_level?.type, t('checkout-form.english-level'))}
                                        </p>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="mb-6 relative">
                            <label htmlFor="" className="text-base text-white mb-3 block">
                                {t('checkout-form.job')}
                                <span className="text-red-500 ml-2">*</span>
                            </label>
                            <input
                                type="text"
                                className="bg-white bg-opacity-5 w-full px-6 py-3 rounded-lg text-white focus:bg-opacity-30"
                                name="profession"
                                placeholder={t('checkout-form.job')}
                                {...register('profession', {
                                    required: true,
                                    minLength: 3,
                                    maxLength: 60
                                })}
                            />
                            {
                                (formValidation.has(errors.profession?.type)) ? (
                                    <p className="text-red-500 mt-3">
                                        {formValidation.first(errors.profession?.type, t('checkout-form.job'), 3, 60)}
                                    </p>
                                ) : null
                            }
                        </div>

                        {/*
                        <p className="text-base text-white">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, beatae cum
                            id ipsa optio rerum.
                        </p>
                        */}
                    </div>
                </div>
                <div
                    className="flex flex-col sm:flex-row justify-end mt-12 space-y-6 sm:space-y-0 sm:space-x-8">
                    <h1 className="text-4xl lg:text-6xl font-bold text-white text-center sm:text-right"><span
                    > </span> {amount} $</h1>
                    <button
                        disabled={processing}
                        className={`${!processing ? 'text-lg lg:text-xl text-black-10 bg-white font-bold py-3 px-8 rounded-full' : 'text-lg lg:text-xl text-black-10 bg-white font-bold py-3 px-8 rounded-full opacity-50 cursor-not-allowed'}`}>
                        {
                            !processing ? (
                                <span>
                                    {t('checkout-form.complete-purchase')}
                                </span>
                            ) : <span>
                                <div className='flex'>
                                    <div className={'w-4/5'}>
                                        {t('checkout-form.treat')}...
                                    </div>
                                    <div className="w-1/5">
                                        <img src="/images/dural-ring.svg" style={{width: '28px', float: 'right'}}
                                             alt=""/>
                                    </div>
                                </div>
                            </span>
                        }
                    </button>
                </div>
            </fieldset>
        </form>
    )
};
