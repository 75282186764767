import React, {useState} from "react";
import {useForm} from "react-hook-form";

import FormValidations from "../utils/custom-validation/form-validations";
import countries from "../utils/country-list";
import typeVisas from "../utils/visa-type";
import http from '../utils/axios'
import {useTranslation} from "react-i18next";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'


const yearCounts = []

for (let i = 0; i < 100; i++) {
    yearCounts.push(i);
}


export const ReservationForm = ({reservationFormOpener}) => {
    const [processing, setProcessing] = useState(false);
    const [haveValidateVisa, setHaveValidateVisa] = useState(false);
    const [userChoice, setUserChoice] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [phoneValidation, setPhoneValidation] = useState({
        isValid: true,
        key: null,
    });
    const {t, i18n} = useTranslation();

    const {register, formState: {errors}, reset, handleSubmit} = useForm();
    const formValidation = new FormValidations(i18n.language);

    const onSubmit = async (data) => {
        const paymentData = {
            ...data,
            nbr_annee: parseInt(data.nbr_annee),
            phoneNumber,
            name: 'Package : ' + t('pricing.interview.title'),
            amount: 25,
            pack: t('pricing.interview.title'),
            description: t('pricing.interview.target'),
        }

        setProcessing(true)
        http.post(`payments/free-reservations`, paymentData)
            .then(async ({data}) => {
                reset()
                setProcessing(false);
                if (data?.data) {
                    window.location.href = data?.data?.redirectUrl;
                }
            }).catch((error) => {
            setProcessing(false);
        })

        /*
        data = {
            ...data,
            phoneNumber,
            type: 'GRATUIT',
        }
        http.post(`payments/free-reservations`, data)
            .then(async ({data}) => {
                reset()
                setProcessing(false);
                reservationFormOpener(false);
            }).catch((error) => {

            setProcessing(false);
        })*/
    };

    const validatePhoneNumber = () => {
        if (!phoneNumber) {
            setPhoneValidation({
                isValid: false,
                key: 'required',
            })
            return 0;
        }
        if (!isValidPhoneNumber(phoneNumber + '')) {
            setPhoneValidation({
                isValid: false,
                key: 'regex',
            })
            return 0;
        }
    }

    return (
        <>
            {
                !userChoice ? (
                    <div className={'text-center py-12'}>
                        <h1> { t('reservation-form.question') }</h1>
                        <div className="mt-12">
                            <div className="mt-">
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio h-6 w-6 text-green-600 focus:outline-green-600"
                                        name="accountType"
                                        checked={() => {
                                            setHaveValidateVisa(true);
                                            setUserChoice(true)
                                        }}
                                        onChange={() => {
                                            setHaveValidateVisa(true);
                                            setUserChoice(true)
                                        }}
                                        value={true}
                                    />
                                    <span className="ml-2">
                                        { t('reservation-form.yes') }
                                    </span>
                                </label>
                                <label className="inline-flex items-center ml-20">
                                    <input
                                        type="radio" className="form-radio h-6 w-6 text-red-600 focus:outline-red-600"
                                        name="accountType"
                                        checked={() => {
                                            setHaveValidateVisa(false);
                                            setUserChoice(true)
                                        }}
                                        onChange={() => {
                                            setHaveValidateVisa(false);
                                            setUserChoice(true)
                                        }}
                                        value={false}
                                    />
                                    <span className="ml-2">
                                        { t('reservation-form.no') }
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                ) : null
            }

            {
                userChoice ? (
                    haveValidateVisa ? (
                        <form className="" onSubmit={handleSubmit(onSubmit, validatePhoneNumber)}>
                            <div className="grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <div className="mb-2">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('reservation-form.username')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 focus:bg-opacity-30"
                                            name="username"
                                            placeholder={t('reservation-form.username')}
                                            {...register('username', {required: true, minLength: 6, maxLength: 60})}
                                        />
                                        {
                                            (formValidation.has(errors.username?.type)) ? (
                                                <p className="text-red-500 mt-3">
                                                    {formValidation.first(errors.username?.type, t('reservation-form.username'), 6, 60)}
                                                </p>
                                            ) : null
                                        }
                                    </div>

                                    <div className="mb-2 relative">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('reservation-form.email')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 focus:bg-opacity-30"
                                            name="email"
                                            placeholder={t('reservation-form.email')}
                                            {...register('email', {
                                                required: true,
                                                pattern: {value: /\S+@\S+\.\S+/, message: ""}
                                            })}
                                        />
                                        {
                                            (formValidation.has(errors.email?.type)) ? (
                                                <p className="text-red-500 mt-3">
                                                    {formValidation.first(errors.email?.type, t('reservation-form.email'), null, null)}
                                                </p>
                                            ) : null
                                        }
                                    </div>

                                    <div className="mb-2 relative">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('checkout-form.job')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 focus:bg-opacity-30"
                                            name="profession"
                                            placeholder={t('checkout-form.job')}
                                            {...register('profession', {
                                                required: true,
                                                minLength: 3,
                                                maxLength: 60
                                            })}
                                        />
                                        {
                                            (formValidation.has(errors.profession?.type)) ? (
                                                <p className="text-red-500 mt-3">
                                                    {formValidation.first(errors.profession?.type, t('checkout-form.job'), 3, 60)}
                                                </p>
                                            ) : null
                                        }
                                    </div>

                                    <div className="mb-2 ">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('reservation-form.country')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <div className="mb-2 relative">
                                            <svg
                                                className="absolute right-3 top-3 select-none pointer-events-none w-4 mt-1"
                                                viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.21978 10.7801L2.77979 12.2201L15.2798 24.7201L15.9998 25.4061L16.7198 24.7191L29.2198 12.2191L27.7798 10.7791L15.9998 22.5641L4.21978 10.7801Z"
                                                    fill="black"
                                                />
                                            </svg>
                                            <select
                                                name="country"
                                                {...register('country', {
                                                    required: true
                                                })}
                                                className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 appearance-none"
                                            >
                                                {
                                                    countries.map(value => (
                                                        <option key={value} value={value}>
                                                            {value}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                (formValidation.has(errors.country?.type)) ? (
                                                    <p className="text-red-500 mt-3">
                                                        {formValidation.first(errors.country?.type, t('reservation-form.country'))}
                                                    </p>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                </div>


                                <div>
                                    <div className="mb-2">
                                        <div className="mb-2">
                                            <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                                {t('reservation-form.phone-number')}
                                                <span className="text-red-500 ml-2">*</span>
                                            </label>
                                            <PhoneInput
                                                placeholder={t('reservation-form.phone-number')}
                                                value={phoneNumber}
                                                onChange={(value) => {
                                                    setPhoneNumber(value)
                                                    setPhoneValidation({
                                                        isValid: true,
                                                        key: null,
                                                    })
                                                }}
                                            />
                                            {
                                                (!phoneValidation.isValid) ? (
                                                    <p className="text-red-500 mt-3">
                                                        {t(`validations.${phoneValidation.key}`).replace(':field', t('reservation-form.phone-number'))}
                                                    </p>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('checkout-form.year-count')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <div className="relative">
                                            <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                                 width="24"
                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                                    fill="#F8F8F8"/>
                                            </svg>
                                            <select
                                                name="nbr_annee"
                                                {...register('nbr_annee', {
                                                    required: true
                                                })}
                                                className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 appearance-none"
                                            >
                                                {
                                                    yearCounts.map(value => (
                                                        <option key={value} value={value}>
                                                            {value}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                (formValidation.has(errors.nbr_annee?.type)) ? (
                                                    <p className="text-red-500 mt-3">
                                                        {formValidation.first(errors.nbr_annee?.type, t('checkout-form.year-count'))}
                                                    </p>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className="mb-2 relative">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('reservation-form.visa-type')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <div className="mb-2 relative">
                                            <svg
                                                className="absolute right-3 top-3 select-none pointer-events-none w-4 mt-1"
                                                viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.21978 10.7801L2.77979 12.2201L15.2798 24.7201L15.9998 25.4061L16.7198 24.7191L29.2198 12.2191L27.7798 10.7791L15.9998 22.5641L4.21978 10.7801Z"
                                                    fill="black"/>
                                            </svg>
                                            <select
                                                name="typeVisa"
                                                {...register('typeVisa', {
                                                    required: true
                                                })}
                                                className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 appearance-none"
                                            >
                                                {
                                                    typeVisas.map(value => (
                                                        <option key={value} value={value}>
                                                            {(t(`type-visa.${value}`))}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                (formValidation.has(errors.typeVisa?.type)) ? (
                                                    <p className="text-red-500 mt-3">
                                                        {formValidation.first(errors.typeVisa?.type, t('reservation-form.visa-type'))}
                                                    </p>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                            {t('checkout-form.english-level')}
                                            <span className="text-red-500 ml-2">*</span>
                                        </label>
                                        <div className="mb-2 relative">
                                            <svg className="absolute right-3 top-3 select-none pointer-events-none"
                                                 width="24"
                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17 9.17C16.8126 8.98375 16.5592 8.87921 16.295 8.87921C16.0308 8.87921 15.7774 8.98375 15.59 9.17L12 12.71L8.46001 9.17C8.27265 8.98375 8.0192 8.87921 7.75501 8.87921C7.49082 8.87921 7.23737 8.98375 7.05001 9.17C6.95628 9.26296 6.88189 9.37356 6.83112 9.49542C6.78035 9.61728 6.75421 9.74799 6.75421 9.88C6.75421 10.012 6.78035 10.1427 6.83112 10.2646C6.88189 10.3864 6.95628 10.497 7.05001 10.59L11.29 14.83C11.383 14.9237 11.4936 14.9981 11.6154 15.0489C11.7373 15.0997 11.868 15.1258 12 15.1258C12.132 15.1258 12.2627 15.0997 12.3846 15.0489C12.5064 14.9981 12.617 14.9237 12.71 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2189 10.2646C17.2697 10.1427 17.2958 10.012 17.2958 9.88C17.2958 9.74799 17.2697 9.61728 17.2189 9.49542C17.1681 9.37356 17.0937 9.26296 17 9.17Z"
                                                    fill="#F8F8F8"/>
                                            </svg>
                                            <select
                                                name="english_level"
                                                {...register('english_level', {
                                                    required: true
                                                })}
                                                className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 appearance-none"
                                            >
                                                {
                                                    ['Aucun', 'Débutant', 'Intermédiaire', 'Avancé'].map(value => (
                                                        <option key={value} value={value}>
                                                            {(t(`level.${value}`))}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                (formValidation.has(errors.english_level?.type)) ? (
                                                    <p className="text-red-500 mt-3">
                                                        {formValidation.first(errors.english_level?.type, t('checkout-form.english-level'))}
                                                    </p>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-2">
                                <label htmlFor="" className="text-base text-black-10 mb-3 block">
                                    {t('reservation-form.reason')}
                                    <span className="text-red-500 ml-2">*</span>
                                </label>
                                <textarea
                                    placeholder={t('reservation-form.reason')} rows="2"
                                    className="bg-transparent border w-full px-6 py-3 rounded-lg text-black-10 focus:bg-opacity-30"
                                    name='reason'
                                    {...register('reason', {required: true, minLength: 10, maxLength: 250})}
                                > </textarea>
                                {
                                    (formValidation.has(errors.reason?.type)) ? (
                                        <p className="text-red-500 mt-3">
                                            {formValidation.first(errors.reason?.type, t('reservation-form.reason'), 10, 250)}
                                        </p>
                                    ) : null
                                }
                            </div>
                            <div className="text-center">
                                <button
                                    disabled={processing}
                                    className={`${!processing ? 'py-4 px-20 bg-black text-white font-black text-sm rounded-full inline-block' : 'py-4 px-20 bg-gray-200 border-2 text-whites font-black text-sm rounded-full inline-block opacity-50 cursor-not-allowed'}`}
                                >
                                    {
                                        !processing ? (
                                            <span className='uppercase'>
                                {t('buttons.reserve-interview')}
                            </span>
                                        ) : <span>
                                <div className='flex'>
                                    <div className="w-full">
                                        <img src="/images/dural-ring.svg" style={{width: '28px', float: 'right'}}
                                             alt=""/>
                                    </div>
                                </div>
                            </span>
                                    }
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className={'text-center'}>
                            <div className="w-16 md:w-32 m-auto mb-8">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>
                                </svg>
                            </div>
                            { t('reservation-form.paragraph-1') }
                            <br/>
                            <br/>
                            { t('reservation-form.paragraph-2') }
                            <a href="https://bit.ly/2QLGaXT"
                               className={'hover:text-blue-500 text-blue-800 font-semibold'} target={'_blank'}>
                                { t('reservation-form.link-click') }
                            </a>
                        </div>
                    )
                ) : null
            }
        </>
    )
};
