import React from 'react'
import {useTranslation} from "react-i18next";

const HowItWork = ({reservationFormOpener}) => {
    const { t } = useTranslation();
    return (
        <>
            <section className=" container xl:max-w-5xl mx-auto lg:px-16 px-4 py-14 md:py-16 lg:py-20">
                <div className="max-w-[632px] mx-auto text-center mb-14">
                    <h1 className="text-2xl sm:text-4xl md:text-42 font-black text-black-10 uppercase mb-4 md:mb-7">
                        { t('how-it-works.title') }
                    </h1>
                    <p className="text-base sm:text-lg md:text-xl text-gray-80">
                        { t('how-it-works.paragraph') } :
                    </p>
                </div>
                <div className="grid md:grid-cols-2 gap-y-5 md:gap-y-14 gap-x-8 lg:gap-x-[52px] mb-10">
                    <div className="border border-black">
                        <div className="w-full h-1.5 bg-black-10"></div>
                        <div className="pt-3 pl-4 pr-4 lg:pr-10 pb-8">
                            <p
                                className="text-xl text-white font-medium w-10 h-10 bg-black-10 rounded-full flex items-center justify-center mb-2">
                                1
                            </p>
                            <p className="text-lg font-black text-black-10 leading-5">
                                { t('how-it-works.1') }
                            </p>
                        </div>
                    </div>

                    <div className="border border-black">
                        <div className="w-full h-1.5 bg-black-10"></div>
                        <div className="pt-3 pl-4 pr-4 lg:pr-10 pb-8">
                            <p
                                className="text-xl text-white font-medium w-10 h-10 bg-black-10 rounded-full flex items-center justify-center mb-2">
                                2
                            </p>
                            <p className="text-lg font-black text-black-10 leading-5">
                                { t('how-it-works.2') }
                            </p>
                        </div>
                    </div>

                    <div className="border border-black">
                        <div className="w-full h-1.5 bg-black-10"></div>
                        <div className="pt-3 pl-4 pr-4 lg:pr-10 pb-8">
                            <p
                                className="text-xl text-white font-medium w-10 h-10 bg-black-10 rounded-full flex items-center justify-center mb-2">
                                3
                            </p>
                            <p className="text-lg font-black text-black-10 leading-5">
                                { t('how-it-works.3') }
                            </p>
                        </div>
                    </div>

                    <div className="border border-black">
                        <div className="w-full h-1.5 bg-black-10"></div>
                        <div className="pt-3 pl-4 pr-4 lg:pr-10 pb-8">
                            <p
                                className="text-xl text-white font-medium w-10 h-10 bg-black-10 rounded-full flex items-center justify-center mb-2">
                                4
                            </p>
                            <p className="text-lg font-black text-black-10 leading-5">
                                { t('how-it-works.4') }
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-black-10">
                <div className="container md:max-w-[615px] mx-auto text-center py-[72px]">
                    <p className="text-base sm:text-lg md:text-xl text-white mb-9">
                        { t('how-it-works.reservations.1') }
                        <span className="font-black"> { t('how-it-works.reservations.2') } </span>
                        { t('how-it-works.reservations.3') }
                    </p>
                    <button
                        onClick={() => {
                            reservationFormOpener(true)
                        }}
                        className="text-sm font-black text-black-10 leading-4 py-4 px-6 bg-white rounded-full inline-block uppercase">
                        { t('buttons.free-reservation') }
                    </button>
                </div>
            </section>
        </>
    )
}

export default HowItWork
