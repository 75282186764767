import React, {useState} from 'react'
import {useForm} from "react-hook-form";
import FormValidations from "../utils/custom-validation/form-validations";
import {CheckoutForm} from "./CheckoutForm";
import {useTranslation} from "react-i18next";

const coachingPack = [
    {
        title: "pricing.silver.title",
        target: "pricing.silver.target",
        content: ["pricing.silver.content.1", "pricing.silver.content.2", "pricing.silver.content.3", "pricing.silver.content.4", "pricing.silver.content.5", "pricing.silver.content.6", "pricing.silver.content.7", "pricing.silver.content.8", "pricing.silver.content.9", "pricing.silver.content.10"],
        price: 150
    },
    {
        title: "pricing.gold.title",
        target: "pricing.gold.target",
        content: ["pricing.gold.content.1", "pricing.gold.content.2", "pricing.gold.content.3", "pricing.gold.content.4", "pricing.gold.content.5", "pricing.gold.content.6", "pricing.gold.content.7", "pricing.gold.content.8"],
        price: 250
    },
    {
        title: "pricing.diamond.title",
        target: "pricing.diamond.target",
        content: ["pricing.diamond.content.1", "pricing.diamond.content.2", "pricing.diamond.content.3", "pricing.diamond.content.4", "pricing.diamond.content.5", "pricing.diamond.content.6"],
        price: 350
    }
]

const yearCounts = []

for (let i = 0; i < 100; i++) {
    yearCounts.push(i);
}

const Pricing = () => {
    const [paymentSection, setPaymentSection] = useState(false)
    const {t} = useTranslation();
    const [data, setData] = useState({})
    const {register, formState: {errors}, handleSubmit} = useForm();
    const formValidation = new FormValidations('fr');

    const handleClick = (currentPack) => {
        setPaymentSection(!paymentSection)
        setData(currentPack)
    }

    return (
        <section id="price" className="bg-gray-90">
            <div className="container xl:max-w-7xl mx-auto lg:px-16 px-4 py-14 md:py-16 lg:pb-28 lg:pt-[70px] relative">
                {!paymentSection ? (
                    <div>
                        <h1 className="text-2xl sm:text-4xl md:text-42 font-black text-white uppercase text-center mb-10 md:mb-16">
                            {t("pricing.title")}
                        </h1>

                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-14 xl:gap-[62px]">

                            {
                                coachingPack.map(element => (
                                        <div className="bg-white flex flex-col justify-between">
                                            <div>
                                                <div className="py-[18px] text-center border-b border-gray-80">
                                                    <h6 className="text-base md:text-lg font-black text-black-10 text-center">{t(element.title)}</h6>
                                                </div>

                                                <div className="pt-5 px-6">
                                                    <p className="text-lg text-gray-90 mb-6 lg:h-40 xl:h-28"><span
                                                        className="font-black"> {t('pricing.target')}</span> : {t(element.target)}
                                                    </p>

                                                    <div className=" space-y-8">
                                                        <ul className="space-y-3">

                                                            {element.content.map(item => (
                                                                <li className="flex items-start space-x-3">
                                                                    <svg className="min-w-[18px] mt-1" width="18"
                                                                         height="13"
                                                                         viewBox="0 0 18 13"
                                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17 1 6 12 1 7" stroke="#454545"
                                                                              stroke-width="2"
                                                                              stroke-linecap="round"
                                                                              stroke-linejoin="round"/>
                                                                    </svg>
                                                                    <span
                                                                        className="text-base md:text-lg md:leading-[22px] text-gray-70">
                                                                {t(item)}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-5 px-6 pb-3">
                                                <button
                                                    className="w-full py-3 px-5 bg-black-10 flex justify-between text-sm font-bold text-white"
                                                    onClick={() => handleClick(element)}>
                                                    <span>
                                                        {t('buttons.i-reserve')}
                                                    </span>
                                                    <span>{element.price}$</span>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                )
                            }

                        </div>
                    </div>
                ) : (
                    <div>
                        <button
                            className="bg-white px-5 py-3 rounded-lg shadow-10 absolute left-6 top-0 mt-4 sm:m-10"
                            onClick={() => {
                                setPaymentSection(!paymentSection)
                                setData({})
                            }}
                        >
                            <svg width="24"
                                 height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 5L4 12L11 19" stroke="#000" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M4 12H20" stroke="#000" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div className="max-w-6xl mx-auto mt-8 sm:mt-0">
                            <h1
                                className="text-2xl sm:text-4xl md:text-42 font-black text-white uppercase text-center mb-6 md:mb-8"
                            >
                                {t('pricing.package')} {t(data?.title)}
                            </h1>
                            <p className="text-base md:text-lg lg:text-xl text-white text-center mb-10 md:mb-14">
                                {t(data?.target)}
                            </p>
                            <CheckoutForm
                                amount={data.price} description={t(data.target)}
                                name={'Package : ' + t(data?.title)} pack={t(data?.title)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default Pricing
