import Mainrouter from "Mainrouter";

function App() {
    return (
        <>
            <Mainrouter/>
        </>
    );
}

export default App;
