import React from 'react'
import {useTranslation} from "react-i18next";

const Hero = ({reservationFormOpener}) => {
    const {t, ready} = useTranslation();

    return (
        <section className="container sm:max-w-screen-2xl mx-auto  md:pr-16 md:pl-0 px-4">
            <div className="grid md:grid-cols-2 gap-10 lg:gap-0 items-center">
                <div className={'md:pr-8'}>
                    <img src='images/bg02.jpg'
                         className="w-full h-[300px] sm:h-[400px] md:h-[480px] lg:h-[600px] object-cover object-top"
                         alt='background '/>
                </div>
                <div className="text-center md:text-left md:max-w-[478px] md:pl-4">

                    <h4 className="text-lg sm:text-xl lg:text-2xl font-medium leading-[29px] text-yellow-10 mb-2">
                        {t("heroes.subtitle")}
                    </h4>
                    <h1
                        className="text-3xl sm:text-4xl lg:text-5xl xl:text-52 xl:leading-[62px] text-black-10 font-black uppercase mb-[18px]"
                    >
                        {t("heroes.title")}
                    </h1>
                    <p className="text-base sm:text-lg md:text-xl text-gray-80 mb-16">
                        {t("heroes.paragraph")}
                    </p>
                    <button
                        onClick={() => {
                            reservationFormOpener(true)
                        }}
                        className="py-4 px-7 bg-black text-white font-black text-sm rounded-full inline-block uppercase">
                        {t("buttons.free-reservation")}
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Hero
