import React, {useState, useEffect} from 'react'
import logo from 'assets/images/logo.png'
import {Link} from "react-router-dom";
import {NavHashLink} from 'react-router-hash-link';
import {useTranslation} from "react-i18next";

const languages = [
    {
        title: 'Français',
        code: 'fr',
        logo: '/images/fr.png'
    },
    {
        title: 'English',
        code: 'en',
        logo: '/images/en.png'
    },
]
const Navbar = () => {
    const {t, i18n, ready} = useTranslation();
    const [open, setOpen] = useState(false)
    const [currentLang, setCurrentLang] = useState({})
    const [dropDowned, setDropDowned] = useState(false)

    useEffect(() => {
        if (i18n?.language) {
            languages.map(item => {
                if (i18n.language.split('-').includes(item.code)) {
                    setCurrentLang(item);
                }
            })
        }
    });

    const handleClick = () => setOpen(!open)
    return (

        <header
            className="container xl:max-w-7xl mx-auto lg:px-16 px-4"
            onMouseLeave={() => setOpen(false)}
        >
            <div className="flex flex-col md:items-center md:justify-between md:flex-row py-4 md:py-6">
                <div className="flex flex-row items-center justify-between">
                    <Link to="/" className="text-2xl font-black leading-[29px] text-black uppercase"><img
                        src={logo} alt="" className="h-8"/></Link>
                    <button
                        className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
                        onClick={handleClick}
                    >
                        <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                            {open ? (
                                <path x-show="open" fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"> </path>
                            ) : (
                                <path x-show="!open" fillRule="evenodd"
                                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                      clipRule="evenodd"> </path>
                            )}
                        </svg>
                    </button>
                </div>
                <nav
                    className={`flex-col flex-grow  pb-4 md:pb-0 md:flex md:justify-end md:flex-row md:space-x-9 ${open ? 'flex' : 'hidden'}`}>
                    <div
                        className="mt-2 md:mt-0 relative"
                        onClick={() => {
                            setDropDowned(true)
                        }}
                    >
                        <svg className="absolute right-0 pt-3 w-3.5 pointer-events-none" viewBox="0 0 17 11" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 2L8.5 9L15.5 2" stroke="#090447" strokeWidth="3" strokeLinejoin="round"/>
                        </svg>
                        <button className="flex items-center justify-between w-full pr-6">
                            <div className="flex items-center space-x-1">
                                <img src={currentLang.logo} alt="" className="w-8"/>
                                <p className="text-sm font-black text-gray-90 uppercase">{currentLang.code}</p>
                            </div>
                        </button>
                        {
                            dropDowned ? (
                                <div
                                    className="absolute right-0 top-12 w-40 bg-white shadow px-5"
                                    onMouseLeave={() => {
                                        setDropDowned(false)
                                    }}
                                    onMouseEnter={() => {
                                        setDropDowned(true)
                                    }}
                                >
                                    <ul>
                                        {
                                            languages.map((item, key) => {
                                                return (
                                                    <li
                                                        key={key}
                                                        className="py-1.5"
                                                        onClick={() => {
                                                            setCurrentLang(item)
                                                            i18n.changeLanguage(item.code)
                                                            setDropDowned(false)
                                                        }}
                                                    >
                                                        <div className="flex items-center space-x-1 cursor-pointer">
                                                            <img src={item.logo} alt="" className="w-8"/>
                                                            <p className="text-sm font-black text-gray-90 uppercase">{item.code}</p>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            ) : null
                        }
                    </div>

                    <NavHashLink
                        to="/#coaching"
                        className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4">
                        {t("nav.coaching")}
                    </NavHashLink>
                    <Link className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4" to="/my-history">
                        {t("nav.my-history")}
                    </Link>

                    <a className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4"
                       href="https://books.komiafetse.com" target="_blank" rel='noreferrer'
                    >
                        {t("nav.my-books")}
                    </a>
                    <NavHashLink
                        to="/#price"
                        activeClassName="selected"
                        className="py-2 mt-2 md:mt-0 text-sm font-black text-gray-90 leading-4"
                        activeStyle={{color: 'red'}}
                    >
                        {t("nav.pricing")}
                    </NavHashLink>
                </nav>
            </div>
        </header>
    )
}

export default Navbar
