import React from 'react'
import {useTranslation} from "react-i18next";

const About = () => {
    const {t} = useTranslation();
    return (
        <section className="container xl:max-w-7xl mx-auto lg:px-16 px-4 py-14 md:py-16 lg:py-[100px]" id={"coaching"}>
            <div className="grid lg:grid-cols-2 xl:grid-cols-12 gap-10 lg:gap-0 items-center">
                <div className="xl:col-span-5 relative z-0">
                    <img src='/images/photo_2022-01-23_22-50-20.jpg' alt="img"
                         className="w-full h-[380px] sm:h-[500px] lg:h-[900px] xl:h-[630px] object-cover object-top"/>
                </div>
                <div
                    className="xl:col-span-7 bg-white shadow-10 p-6 sm:p-8 lg:py-10 lg:pl-12 lg:pr-8 lg:-ml-12 relative z-10">
                    <h1 className="text-2xl md:text-3xl lg:text-4xl font-black uppercase lg:leading-[43px] mb-3">
                        {t('coaching.title')}
                    </h1>
                    <p className="text-base sm:text-lg md:text-xl xl:leading-6 text-gray-80">
                        {t('coaching.paragraph-1')}
                        <br/>
                        <br/>
                        {t('coaching.paragraph-2')}
                    </p>
                </div>

            </div>
        </section>
    )
}

export default About
